import React, { useState, useEffect } from 'react'
import Layout from '../components/Layout'
import AgendaItem from '../components/AgendaItem'
import { useQuerySubscription } from "react-datocms";

function Agenda() {

  const [ agendaItems, setAgendaItems ] = useState([]);

  const { status, error, data } = useQuerySubscription({
    enabled: true,
    query: query,
    variables: { first: 20 },
    token: '0907f4cfec4809b1b584728b404e77',
  });
  const statusMessage = {
    connecting: 'Connecting to DatoCMS...',
    connected: 'Connected to DatoCMS, receiving live updates!',
    closed: 'Connection closed',
  };

  useEffect(() => {
    if (data) {
      setAgendaItems(data.allAgendas)
    }
    console.log(data);
  }, [data]);
  
  return (
    <div className="agenda-page">
      <Layout>
        <div className="agenda-container">
        {agendaItems && agendaItems.map((item) => {
          return <AgendaItem key={item.id} item={item} />
        })}
        </div>
      </Layout>
    </div>
  )
}

export default Agenda

export const query = `
  query MyQuery {
    allAgendas(orderBy: datetime_ASC) {
      city
      datetime
      description
      zipcode
      ticketLink
      title
      street
      locationName
      image {
        url
      }
      id
    }
  }
`;

