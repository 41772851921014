import moment from "moment";
import React from "react";


function AgendaItem({ item }) {
  const formattedDate = moment(item.datetime).format("D MMM YYYY HH:mm")
 
  return (
    <div id="eventItem">
      <div className="imageDate">
        
        <img src={item.image.url} alt="" />
        <h4 className="date">{moment(item.datetime).format("MMM D")}</h4>
      </div>
      <div className="eventContent">
        <div className="dateContent">
          <p className="date-small">{formattedDate}</p>
          <h4>{item.title}</h4>
          <p>
            {item.description}{" "}
          </p>
        </div>

        <div className="moreInfo">
          <p>{item.locationName}</p>
          <p>{item.street}</p>
          <p>{item.zipcode + " " + item.city}</p>
          <a href={item.ticketLink} target="_blank" >Tickets</a>
        </div>
      </div>
    </div>
  );
}

export default AgendaItem;
